
import { useEffect, useState, useRef } from 'react'
import styles from './styles.module.css'
import { Space } from 'antd';
import NewcastleUni from '../../../../assets/img2024_8/newcastleuni.png'
import Graduate1 from '../../../../assets/img2024_8/graduate1.jpg'
import StudyUK1 from '../../../../assets/img2024_8/studyuk1.jpg'
import StudyUK2 from '../../../../assets/img2024_8/studyuk2.jpg'
import StudyUK3 from '../../../../assets/img2024_8/studyuk3.jpg'
import Luthers from '../../../../assets/img2024_8/luthers2.jpg'
import Scholar from '../../../../assets/img2024_8/scholar.png'
import Nusu from '../../../../assets/img2024_8/nusu.png'

export default function Activities2024({ target }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
    const [key, setKey] = useState('');

    const itemRefs = {
        item1: useRef(null),
        item2: useRef(null),
        item3: useRef(null),
    };

    // 映射数字键到字符串键
    const targetKeyMap = {
        0: 'item1',
        1: 'item2',
        2: 'item3',
    };

    useEffect(() => {
        const targetKey = targetKeyMap[target];

        if (targetKey && itemRefs[targetKey] && itemRefs[targetKey].current) {
            console.log('target:', target);
            console.log(itemRefs[target]);
            itemRefs[targetKey].current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Invalid target key or ref is not set correctly.');
        }
    }, target)

    return (
        <>
            <div className={styles.container}>
                <div ref={itemRefs.item1} className={styles.item}>
                    <Space direction='vertical'>
                        <div>
                            <div className={styles.title}>
                                MSc Computer Science at Newcastle University &#40; Full Time 2023.9-2024.9  &#41;
                            </div>
                            <div className={styles.description}>
                                <i className='iconfont icon-ditu'></i>Newcastle UK
                                &nbsp;
                                <i className='iconfont icon-xuewei'></i>
                                <span style={{ marginLeft: '0.2rem' }}>Distinction</span>
                            </div>
                            <div className={styles.description}>

                            </div>

                        </div>
                        <Space size={30} direction={isMobile ? 'vertical' : 'horizontal'}>
                            <div >
                                <img src={NewcastleUni} className={styles.pic} />
                            </div>
                            <div>
                                <img src={Graduate1} className={styles.graduate} />
                            </div>
                        </Space>

                    </Space>


                </div>
                <div ref={itemRefs.item2} className={styles.item}>
                    <Space direction='vertical'>
                        <div>
                            <div className={styles.title}>
                                British Council Study UK Ambassador
                            </div>
                            <div className={styles.description}>
                                <i className='iconfont icon-ditu'></i>Newcastle UK
                            </div>

                            <div className={styles.description}>
                                <a href='https://study-uk.britishcouncil.org/blog/my-uk-degree-laying-foundation-for-career' target='_blank'>My UK degree is laying the foundation for my career</a>
                            </div>
                        </div>
                        <Space size={30} direction={isMobile ? 'vertical' : 'horizontal'}>
                            <iframe
                                width="460"
                                height="300"
                                style={{ border: 0 }}
                                src="https://www.youtube.com/embed/w12w8aEFwHs?si=vy0g0sVKclkLaZGK"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

                            </iframe>
                        </Space>

                    </Space>


                </div>

                <div ref={itemRefs.item3} className={styles.item}>
                    <Space direction='vertical'>
                        <div>
                            <div className={styles.title}>
                                NUSU Associate Staff  &#40; 2023.10-2024.11  &#41;
                            </div>
                            <div className={styles.description}>
                                <i className='iconfont icon-ditu'></i>Newcastle University Student Union Commercial Team
                            </div>
                        </div>

                        <div className={styles.kitchen}>
                            <img src={Nusu} style={{ width: '18rem', height: '10rem' }} />
                        </div>
                    </Space>
                </div>
            </div>
        </>
    )
}